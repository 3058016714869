import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createPopper } from '@popperjs/core'
import { localize } from 'vee-validate'

export const sizeIconsAction = {
  computed:
  {
    sizeIconsAction() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? '21' : '17')
    },
  },
}

export const sizePaginationListviewResponsive = {
  computed:
  {
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
}

export const showMsgRequest = {
  methods: {
    async showMsgErrorRequest(error) {
      const { message } = error

      if (message !== '') {
        this.$toast.clear()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          html: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
          },
          buttonsStyling: false,
        })
      }
    },
    async showMsgError(error) {
      this.$toast.clear()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Alerta'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error.message,
        },
      })
    },
    async showMsgSuccess(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          text: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}

export const eventsCustomSelects = {
  methods: {
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '375px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    withPopperGroup(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '375px'
      oDropdownList.style.zIndex = '9999'
      oDropdownList.classList.add('v-select-optgroup')

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
  },
}

export const VeeValidateLoadLocale = {
  mounted() {
    this.loadLocale(this.$i18n.locale)
  },
  updated() {
    this.loadLocale(this.$i18n.locale)
  },
  methods: {
    async loadLocale(code) {
      let sCode = code
      if (sCode === 'pt') {
        sCode = 'pt_PT'
      }

      return import(`vee-validate/dist/locale/${sCode}.json`).then(locale => {
        const oLocale = locale
        oLocale.default.messages.nif = this.$t('O campo {_field_} é inválido')
        oLocale.messages.nif = oLocale.default.messages.nif

        localize(sCode, oLocale)
      })
    },
  },
}

export const debounce = (fn, delay) => {
  let timeout

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const disabledScrollBody = () => {
  if (!document.body.className.match('overflow-hidden')) {
    document.body.classList.add('overflow-hidden')
  }
}

export const activatedScrollBody = () => {
  if (document.body.className.match('overflow-hidden')) {
    document.body.classList.remove('overflow-hidden')
  }
}

export const _ = null
